import {Action, action} from 'easy-peasy'
import {HighRiskTrip} from 'Api/controller'
// import { HighRiskTrip } from 'Types/HighRiskv2'

const initialState = {
  highRiskTrip: {
    activities: [],
    adminOfficer: '',
    collaborateOrSupportByOrgs: false,
    collaborateWithLocalAlumniOrContacts: false,
    collectPII: false,
    country: {
      code: '',
      name: '',
      highRisk: false,
      description: ''
    },
    courseDescriptionOrSummaryKey: '',
    department: {
      id: '',
      name: ''
    },
    endDate: '',
    humanResearch: false,
    programId: '',
    id: '',
    localCommunicationsSupport: '',
    localEmergencySupport: '',
    localLogingSupport: '',
    localSupportOrgs: [],
    localTransportSupport: '',
    logisticalSupportFromLocalSupportOrgs: false,
    moreThanTwentyPerFlight: false,
    purposeOfTrip: '',
    reasonForTrip: '',
    startDate: '',
    tripName: '',
    tripLeader: '',
    tripLeaderKrb: '',
    tripYear: ''
  }
}

interface State {
  reloadHighRiskTrip: boolean
  highRiskTrip: HighRiskTrip
}
interface Actions {
  setReloadHighRiskTrip: Action<this, boolean>
  setHighRiskTrip: Action<this, HighRiskTrip>
  resetHighRiskTrip: Action<this>
}
// eslint-disable-next-line
interface Thunks {}

export interface ITripModel extends State, Actions, Thunks {}

export const TripModel: ITripModel = {
  reloadHighRiskTrip: false,
  highRiskTrip: initialState.highRiskTrip,

  // Actions
  setReloadHighRiskTrip: action((state, payload) => {
    state.reloadHighRiskTrip = payload
  }),
  setHighRiskTrip: action((state, payload) => {
    state.highRiskTrip = payload
  }),
  resetHighRiskTrip: action((state) => {
    state.highRiskTrip = initialState.highRiskTrip
  }),
}
