const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "apiPathTravel": "travel-v1",
      "apiPathSearch": "search-v1",
      "apiPathVaccine": "vaccine-v1",
      "apiPathPersonalInformation": "personal-information-v1",
      "apiPathDigitalId": "digital-id-v1",
      "apiAccount": "account-v2",
      "apiDocuSign": "docusign/v1",
      "apiText": "text/v1",
      "atlasUiApi": "atlas-ui-v1"
    }
  },
  "global": {
    "apiHost": "https://global.api.mit.edu",
    "paths": {
      "apiPathTravel": "travel/v1",
      "apiPathSearch": "search/v1",
      "apiPathVaccine": "vaccine/v1",
      "apiPathPersonalInformation": "personal-information/v1",
      "apiPathDigitalId": "digital-id/v1",
      "apiAccount": "account/v2",
      "apiDocuSign": "docusign/v1",
      "apiText": "text/v1",
      "atlasUiApi": "atlas-ui/v1"
    }
  }
};
export default apiConfigs;