import {BaseController} from './BaseController'

import {personalInfoApiTypes} from 'Types'

export type Passport = personalInfoApiTypes.components['schemas']['Passport']
export type Passports = personalInfoApiTypes.components['schemas']['Passports']
export type Visa = personalInfoApiTypes.components['schemas']['Visa']
export type Visas = personalInfoApiTypes.components['schemas']['Visas']

export class PersonalInfoController extends BaseController {
	readonly url_passports: string
	readonly url_visas: string

	constructor() {
		super()
		this.url_passports = `${this.apiHost}/${this.apiPathPersonalInfo}/passports`
		this.url_visas = `${this.apiHost}/${this.apiPathPersonalInfo}/visas`
	}

	async getPassports(id?: string): Promise<Passports> {
		const response = await this.useFetch('GET', 'getPassports', `${this.url_passports}?mitId=${id}`)
		// const response = await this.useFetch('GET', 'getPassports', `${this.url_passports}`)
		if (this.checkStatusCode(response.status)) {
			const data = response.json()
			return data
		} else {
			try {
				const text = await response.json()
				return await Promise.reject(text.message)
			} catch (error) {
				return await Promise.reject('')
			}
		}
	}

  async getVisas(id?: string): Promise<Visas> {
    const response = await this.useFetch('GET', 'getVisaById', `${this.url_visas}?mitId=${id}`)
    // const response = await this.useFetch('GET', 'getVisaById', `${this.url_visas}`)
    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

}
