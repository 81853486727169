import {BaseController as BaseApiController} from 'TemplateConstants/controller'
import apiConfig from '../api-config'

// Override any methods from TemplateConstant BaseController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
  readonly apiPathTravel
  readonly apiPathAccount
  readonly apiPathSearch
  readonly apiPathDocusign
  readonly apiPathPersonalInfo
  readonly apiText
  readonly atlasUiApi
  // readonly apiPathFeature: string
  constructor () {
    super()

    this.apiPathTravel = apiConfig[this.configKey].paths.apiPathTravel
    this.apiPathAccount = apiConfig[this.configKey].paths.apiAccount
    this.apiPathSearch = apiConfig[this.configKey].paths.apiPathSearch
    this.apiPathDocusign = apiConfig[this.configKey].paths.apiDocuSign
    this.apiPathPersonalInfo = apiConfig[this.configKey].paths.apiPathPersonalInformation
    this.apiText = apiConfig[this.configKey].paths.apiText
    this.atlasUiApi = apiConfig[this.configKey].paths.atlasUiApi
  }

  checkStatusCode (status: number): boolean {
    return status >= 200 && status < 300
  }
}
