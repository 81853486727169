import { BaseController } from './'

export interface FeaturesResponse {
	data?: Features
	error?: any
}

export interface Features {
	featureFlags: string[]
	maintMode: boolean
	releaseDate: string
	releaseNotes: string
}

class FeatureController extends BaseController {

	async getFeatures(): Promise<FeaturesResponse> {
		const response = await this.useFetch('GET', 'feature', `${this.apiHost}/${this.atlasUiApi}/features/atlas`)

		if (response.status >= 200 && response.status < 300) {
			let data: Features = await response.json()
			return {data}
		} else {
			return {
				error: true
			}
		}
	}
}

const featureController = new FeatureController()
export default featureController
