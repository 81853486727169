import { Activity, View } from 'Api/controller'
import { Action, action } from 'easy-peasy'
import { components } from 'Types/api'
import { Country } from 'Types/Country'
import { Department } from 'Types/Department'
import { Program } from 'Types/Program'
import { Roles } from 'Types/Roles'
// import { Traveler } from 'Types/Traveler'
import { TripDetail } from 'Types/TripDetail'

export type LocalSupportOrganization = components["schemas"]["LocalSupportOrganization"]
type HighRiskTraveler = components["schemas"]["HighRiskTraveler"]

interface State {
  auth: object
  texts: Record<string, string>
  roles: Roles
  featurs: string[]
  selectedPerson: HighRiskTraveler | null
  selectedTrip: TripDetail | null
  selectedProgram: Program | null
  selectedProgramDetails: Program | null
  selectedActivity: Activity | null
  selectedLocalSupport: LocalSupportOrganization | null
  travelers: HighRiskTraveler[]
  programs: Program[]
  countries: Country[]
  departments: Department[]
  isLoadingMaster: boolean
  showProgramDrawer: boolean
  showPersonDrawer: boolean
  viewSelected: View
}
interface Actions {
  setAuth: Action<this, object>
  setTexts: Action<this, Record<string, string>>
  setRoles: Action<this, Roles>
  setFeatures: Action<this, string[]>
  setSelectedPerson: Action<this, HighRiskTraveler | null>
  setSelectedTrip: Action<this, TripDetail | null>
  setTravelers: Action<this, HighRiskTraveler[]>
  setSelectedProgram: Action<this, Program | null>
  setSelectedProgramDetails: Action<this, Program | null>
  setSelectedActivity: Action<this, Activity | null>
  setSelectedLocalSupport: Action<this, LocalSupportOrganization | null>
  setPrograms: Action<this, Program[]>
  setCountries: Action<this, Country[]>
  setDepartments: Action<this, Department[]>
  setIsLoadingMaster:  Action<this, boolean>
  setShowProgramDrawer:  Action<this, boolean>
  setShowPersonDrawer:  Action<this, boolean>
  setViewSelected: Action<this, View>
}
// eslint-disable-next-line
interface Thunks {}

export interface IGlobalModel extends State, Actions, Thunks {}

export const GlobalModel: IGlobalModel = {
  auth: {},
  texts: {},
  roles: {
    admin: false,
    superAdmin: false,
    manageHighRisk: false
  },
  featurs: [],
  selectedPerson: null,
  selectedTrip: null,
  travelers : [],
  selectedProgram: null,
  selectedProgramDetails: null,
  selectedActivity: null,
  selectedLocalSupport: null,
  programs: [],
  countries: [],
  departments: [],
  isLoadingMaster: false,
  showProgramDrawer: false,
  showPersonDrawer: false,
  viewSelected: 'current',
  // Actions
  setAuth: action((state, payload) => {
    state.auth = payload
  }),
  setTexts: action((state, payload) => {
    state.texts = payload
  }),
  setRoles: action((state, payload) => {
    state.roles = payload
  }),
  setFeatures: action((state, payload) => {
    state.featurs = payload
  }),
  setSelectedPerson: action((state, payload) => {
    state.selectedPerson = payload
  }),
  setTravelers: action((state, payload) => {
    state.travelers = payload
  }),
  setSelectedProgram: action((state, payload) => {
    state.selectedProgram = payload
  }),
  setSelectedActivity: action((state, payload) => {
    state.selectedActivity = payload
  }),
  setSelectedLocalSupport: action((state, payload) => {
    state.selectedLocalSupport = payload
  }),
  setPrograms: action((state, payload) => {
    state.programs = payload
  }),
  setCountries: action((state, payload) => {
    state.countries = payload
  }),
  setIsLoadingMaster: action((state, payload) => {
    state.isLoadingMaster = payload
  }),
  setShowProgramDrawer: action((state, payload) => {
    state.showProgramDrawer = payload
  }),
  setShowPersonDrawer: action((state, payload) => {
    state.showPersonDrawer = payload
  }),
  setDepartments: action((state, payload) => {
    state.departments = payload
  }),
  setSelectedTrip: action((state, payload) => {
    state.selectedTrip = payload
  }),
  setSelectedProgramDetails: action((state, payload) => {
    state.selectedProgramDetails = payload
  }),
  setViewSelected: action((state, payload) => {
    state.viewSelected = payload
  }),
}
