import {BaseController} from './BaseController'
import {HighRiskPrograms, HighRiskTravel, HighRiskTrips, Status} from 'Types/HighRisk'
// import { HighRiskTrip, HighRiskTripsSummaries } from 'Types/HighRiskv2'
import {components} from 'Types/api'

interface RejectReason {
  reason: string
}

export type HighRiskTripSummaries = components["schemas"]["HighRiskTripSummaries"]
export type HighRiskTripSummariesTripSummaries = components["schemas"]["HighRiskTripSummaries"]["tripSummaries"]
export type HighRiskTripSummary = components["schemas"]["HighRiskTripSummary"]
export type HighRiskTrip = components["schemas"]["HighRiskTrip"]
export type HighRiskTravelers = components["schemas"]["HighRiskTravelers"]
export type HighRiskTraveler = components["schemas"]["HighRiskTraveler"] &  components["schemas"]["Traveler"]
export type HighRiskRequirements = components["schemas"]["HighRiskProgramRequirements"] | components["schemas"]["HighRiskBusinessRequirements"]
export type Accommodation = components["schemas"]["Accommodation"]
export type Establishment = components["schemas"]["Establishment"]
export type HighRiskContact = components["schemas"]["HighRiskContact"]
export type Communication = components["schemas"]["Communication"]
export type SocialMedia = components["schemas"]["SocialMedia"]
export type UploadUrl = components["schemas"]["UploadUrl"]
export type GeoLocation = components["schemas"]["GeoLocation"]
export type Endorsement = components["schemas"]["Endorsement"]
export type Envelope = components["schemas"]["Envelope"]
export type Activity = components["schemas"]["Activity"]
export type LocalSupport = components["schemas"]["LocalBusinessSupport"]
export type RequirementsComments = components["schemas"]["RequirementsComments"]
export type RequirementsComment = components["schemas"]["RequirementsComment"]
export type EndorsementStatus = components["schemas"]["EndorsementStatus"]
export type Traveler =  components["schemas"]["Traveler"]

interface SigningGroupUsers {
  userName: string
  email: string
}
export interface Endorsements {
  name: string
  email: string
  signingGroupId?: string
  signingGroupName?: string
  signingGroupUsers?: SigningGroupUsers[],
  roleName: string
  status: 'completed' | 'declined' | 'uncompleted'
  signedDateTime?: string
  deliveredDateTime: string
  declinedDateTime?: string
  sentDateTime: string
  declinedReason?: string
};

export interface PointOfInterst {
  displayType: string
  distance: string
  name: string
  placeId: string
}
/* export interface Traveler {
  endorsementId?: string
  endorsementStatus: {
    endorser:
      | "Faculty or Instructional Leader"
      | "Dean(s) of School"
      | "Head(s) of Department";
    status: "uncompleted" | "completed" | "declined";
  }[]
  mitId: string
  firstName: string
  lastName: string
  name: string
  id: string
  status: "approved" | "updates" | "unapproved" | "rejected"
  from: string
  to: string
} */

export class HighRiskController extends BaseController {
  readonly url_high_risk: string
  readonly url_high_risk_programs: string
  readonly url_high_risk_trips: string
  readonly url_high_risk_trips_upload_url: string
  readonly url_high_risk_events: string
  readonly url_high_risk_requirements: string
  readonly url_high_risk_travelers: string
  readonly url_endorsements: string
  readonly url_points_of_interest: string
  readonly url_envelopes_status: string

  constructor() {
    super()

    this.url_high_risk = `${this.apiHost}/${this.apiPathTravel}/high-risk`
    this.url_high_risk_programs = `${this.apiHost}/${this.apiPathTravel}/high-risk/programs`
    this.url_high_risk_trips = `${this.apiHost}/${this.apiPathTravel}/high-risk/trips`
    this.url_high_risk_trips_upload_url = `${this.apiHost}/${this.apiPathTravel}/high-risk/trips/upload/url`
    this.url_high_risk_events = `${this.apiHost}/${this.apiPathTravel}/high-risk/events`
    this.url_high_risk_requirements = `${this.apiHost}/${this.apiPathTravel}/high-risk/requirements`
    this.url_high_risk_travelers = `${this.apiHost}/${this.apiPathTravel}/high-risk/travelers`
    this.url_endorsements = `${this.apiHost}/${this.apiPathTravel}/high-risk/endorsements`
    this.url_points_of_interest = `${this.apiHost}/${this.apiPathSearch}/points-of-interest`
    this.url_envelopes_status = `${this.apiHost}/${this.apiPathDocusign}/envelopes`
  }

  async getHighRiskTravel (programs: boolean = true, status: Status = 'pending'): Promise<HighRiskTrips> {
    const response = await this.useFetch('GET', 'getHighRiskTravel10101', `${this.url_high_risk}?programs=${programs}&status=${status}`)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  //Programs true returns HighRiskPrograms type
  async getHighRiskTravelPrograms (programs: boolean = false, status: Status = 'pending'): Promise<HighRiskPrograms> {
    const response = await this.useFetch('GET', 'getHighRiskTravel', `${this.url_high_risk}?programs=${programs}&status=${status}`)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async postHighRiskTravel (body: HighRiskTravel): Promise<HighRiskTravel> {
    const response = await this.useFetch('POST', 'postHighRiskTravel', this.url_high_risk, body)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async putHighRiskTravel (body: HighRiskTravel): Promise<any> {
    const response = await this.useFetch('PUT', 'putHighRiskTravel', this.url_high_risk, body)

    if (this.checkStatusCode(response.status)) {
      return true
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getHighRiskTravelById (travelId: string): Promise<HighRiskTravel> {
    const response = await this.useFetch('GET', 'getHighRiskTravelById', `${this.url_high_risk}/${travelId}`)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async putHighRiskTravelStatus (travelId: string, status: Status, rejectReason?: RejectReason): Promise<any> {
    let response: any
    if (status === 'approved') {
      response = await this.useFetch('PUT', 'putHighRiskTravelStatus', `${this.url_high_risk}/${travelId}/approve`)
    }
    else if(status === "pending") {
      response = await this.useFetch('PUT', 'putHighRiskTravelStatus', `${this.url_high_risk}/${travelId}/pending`)
    }
    else {
      response = await this.useFetch('PUT', 'putHighRiskTravelStatus', `${this.url_high_risk}/${travelId}/reject`, rejectReason)
    }

    if (this.checkStatusCode(response.status)) {
      //const data = await response.json()
      return true
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getHighRiskPrograms (travelId: string): Promise<HighRiskTravel> {
    const response = await this.useFetch('GET', `getHighRiskPrograms-${travelId}`, `${this.url_high_risk_programs}/${travelId}`)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getHighRiskTrip (travelId: string): Promise<HighRiskTrip | null> {
    let response = await this.useFetch('GET', 'HighRiskTrip', `${this.url_high_risk_trips}/${travelId}?admin=true`)

    if( response.status === 404 ) {
      return null
    }

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getHighRiskTrips (tripType: "program" | "solo", status?: "current" | "past" ): Promise<HighRiskTripSummaries> {

    let response = await this.useFetch('GET', 'getHighRiskTrips', `${this.url_high_risk_trips}?tripType=${tripType}${status ? `&status=${status}` : '' }`)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async postHighRiskTrips (body: HighRiskTrip): Promise<HighRiskTrip> {
    const response = await this.useFetch('POST', 'postHighRiskTrips', this.url_high_risk_trips, body)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async putHighRiskTrips (body: HighRiskTrip): Promise<any> {
    const response = await this.useFetch('PUT', 'putHighRiskTrips', this.url_high_risk_trips, body)

    if (this.checkStatusCode(response.status)) {
       //Had to remove .json() promise is being rejected
      return await response
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async deleteHighRiskTrips (id: string): Promise<void> {
    const response = await this.useFetch('DELETE', 'deleteHighRiskTrips', `${this.url_high_risk_trips}/${id}`)

    if (this.checkStatusCode(response.status)) {
      return await response
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async putHighRiskTripDocument (url: string, file: File): Promise<void> {
    const response = await this.useFetch('PUT', `putHighRiskTripDocuments-${file.name}`, url, file, {})

    if (this.checkStatusCode(response.status)) {
       //Had to remove .json() promise is being rejected
      return await response
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getHighRiskTripDocument (url: string): Promise<Blob> {
    // const response = await this.useFetch('GET', `getHighRiskTripDocument`, url)
    const response = await fetch(url)

    if (this.checkStatusCode(response.status)) {
      return await response.blob()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async postHighRiskTripDocumentUrl (body: UploadUrl): Promise<UploadUrl> {
    const response = await this.useFetch('POST', 'postHighRiskTripDocumentUrl', this.url_high_risk_trips_upload_url, body)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getHighRiskTravellers (programId : string): Promise<HighRiskTravelers> {
    let response = await this.useFetch('GET', 'getHighRiskTravellers', `${this.url_high_risk_travelers}?programId=${programId}`)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getHighRiskRequirements (id: string): Promise<HighRiskRequirements> {
    let response = await this.useFetch('GET', 'getHighRiskTravellers', `${this.url_high_risk_requirements}?tripId=${id}`)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getPointsOfInterest (lat: string, lng: string): Promise<PointOfInterst[]> {
    let response = await this.useFetch('GET', `getPointsOfInterest-${lat},${lng}`, `${this.url_points_of_interest}?latitude=${lat}&longitude=${lng}`)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async postHighRiskEndorsement  (body: Endorsement): Promise<Envelope> {
    const response = await this.useFetch('POST', 'postHighRiskEndorsement', this.url_endorsements, body)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getUpdatedHighRiskRequirements (id: string): Promise<RequirementsComments> {
    const response = await this.useFetch('GET', 'updateHighRiskRequirements', `${this.url_high_risk_requirements}/${id}/updates`)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async updateHighRiskRequirements (id: string, body: RequirementsComment): Promise<any> {
    const response = await this.useFetch('PUT', 'updateHighRiskRequirements', `${this.url_high_risk_requirements}/${id}/updates`, body)

    if (this.checkStatusCode(response.status)) {
       //Had to remove .json() promise is being rejected
      return await response
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async approveHighRiskTrip (id: string): Promise<any> {
    const response = await this.useFetch('PUT', 'rejectHighRiskTrip', `${this.url_high_risk_requirements}/${id}/approve`)

    if (this.checkStatusCode(response.status)) {
       //Had to remove .json() promise is being rejected
      return await response
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async rejectHighRiskTrip (id: string, body: RequirementsComment): Promise<any> {
    const response = await this.useFetch('PUT', 'rejectHighRiskTrip', `${this.url_high_risk_requirements}/${id}/reject`, body)

    if (this.checkStatusCode(response.status)) {
       //Had to remove .json() promise is being rejected
      return await response
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getEnvelopesStatus (envelopeId: string): Promise<any> {
    const response = await this.useFetch('GET', `getEnvelopesStatus-${envelopeId}`, `${this.url_envelopes_status}/${envelopeId}/status`)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async fetchS3Bucket (url: string): Promise<any> {
    const response = await this.useFetch('GET', 'fetchS3Bucket', url, undefined, {})

    if (this.checkStatusCode(response.status)) {
      return await response.blob()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }
}

